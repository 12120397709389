<template>

<app-wrapper class="history">

	<app-wrapper :content="true" :text="true" :center="true" class="history-content">

		<h2>{{ $state.page.history_title }}</h2>

		<p v-html="$state.page.history_text" />
		
	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.history {
	padding: 64px 0px;
	background-color: #112A45;
	background-image: url(~@/assets/about.history.png);
	background-position: 50% 50%;
	background-size: cover;
	
}

.is-tablet .history {
	padding: 40px 0px;
}

.is-mobile .history {
	padding: 30px 0px;
}

.history-content {
	padding: 0px 100px;
}

.history-content >>> h2,
.history-content >>> p {
	color: #fff!important;
}

</style>
